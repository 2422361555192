import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { ProductDetails } from '../../../../global';

type ProductProps = {
  product: ProductDetails;
};

const Product = ({ product }: ProductProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer  bg-white '
      }
      onClick={() => {
        navigate(`/products/${product.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-medium">
            {product.defaultName}
          </p>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold"></p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Product;
